import { PrismicImage, PrismicPageDefinition, PrismicRichText } from "./types";

export type TypeAgencyPartnerBrochurePage = {
  // Hero
  title: string;
  meta_title: string;
  hero_image: PrismicRichText;
  hero_text: PrismicRichText;
  hero_subtitle: PrismicRichText;
  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;

  // Form
  form_heading: string;
  form_subheading: PrismicRichText;
  form_image: PrismicImage;

  // CTA
  cta_text: string;
  cta_button_text1: string;
  cta_link: PrismicPageDefinition;
  second_cta_button_text: string;
  second_cta_button_link: PrismicPageDefinition;
};

export type TypeAgencyPartnerBrochureQueryResult = {
  allPrismicAgencyPartnerProgramBrochure: {
    edges: Array<{
      node: { data: TypeAgencyPartnerBrochurePage };
    }>;
  };
};

export const getPage = (
  data: TypeAgencyPartnerBrochureQueryResult
): TypeAgencyPartnerBrochurePage => {
  return data.allPrismicAgencyPartnerProgramBrochure.edges[0].node.data;
};
