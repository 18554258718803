import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./agency-partner.scss";
import {
  getPage,
  TypeAgencyPartnerBrochurePage,
  TypeAgencyPartnerBrochureQueryResult,
} from "../../utils/queries/agency_partner_program_brochure";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";

import HubspotForm from "../../components/hubspot-form";

import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";

const query = graphql`
  {
    allPrismicAgencyPartnerProgramBrochure {
      edges {
        node {
          id
          data {
            body {
              ... on PrismicAgencyPartnerProgramBrochureBodyHubspotForm {
                id
                slice_type
                slice_label
                primary {
                  form_id
                  portal_id
                }
              }
            }
            cta_button_text
            cta_button_text1
            cta_text
            form_heading
            form_image {
              alt
              copyright
              thumbnails
              url
            }
            form_subheading {
              html
              raw
              text
            }
            hero_subtitle
            hero_text {
              html
              raw
              text
            }
            page_meta_description
            page_meta_thumbnail {
              alt
              copyright
              thumbnails
              url
            }
            second_cta_button_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
            second_cta_button_text
            title
            meta_title
            cta_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const AgencyPartnerBrochurePage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeAgencyPartnerBrochureQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicAgencyPartner",
    true
  ) as TypeAgencyPartnerBrochurePage;

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="agency-program-brochure">
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={RichTextRender(result.hero_text)}
          subheader={RichTextRender(result.hero_subtitle)}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="form_section">
          <div className="flex justify-center top-part">
            <div className="lg:flex items-center justify-center">
              <div className="col-12 col-lg-5 img-wrap text-center">
                <img src={result.form_image.url} alt={result.form_image.alt} />
              </div>
              <div className="col-12 col-lg-1"></div>
              <div className="col-12 col-lg-6 text-left">
                <h2 className="title blue">
                  {RichTextRender(result.form_heading)}
                </h2>
                <div className="ul-wrap">
                  {RichTextRender(result.form_subheading)}
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-part flex justify-center">
            <div className="col-12 col-lg-6 bottom-inner text-center">
              <h3>Download the Brochure</h3>
              <HubspotForm
                formId="e7edb2ae-8ab6-4d1b-bada-0930ffbe2d59"
                portalId="5096486"
              />
            </div>
          </div>
        </section>

        <section className="a-button" id="join-our-agency-partner-program">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-6 text-left">
                <div className="description">
                  <h3>Join our Agency Partner Program</h3>
                  <p>
                    Supercharge your agency business as an amazee.io partner.
                    Unlock new opportunities and differentiate your services
                    with the leading ZeroOps platform. The amazee.io Agency
                    Partner Program will help you deliver outstanding services
                    to joint customers of every size, in every industry, around
                    the world.&nbsp;
                  </p>
                  <p>Ready to expand your business?&nbsp;</p>
                  <p>
                    <a href="/agency-partners/team-up-with-us">
                      Team up with us
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4 text-center">
                <img
                  src="https://amazeeio.cdn.prismic.io/amazeeio/4ab7b2f1-e4a5-48a6-800c-ba7c28089065_PP+Team+Up.svg"
                  alt="Team up with us"
                />
              </div>
            </div>

            <div className="col-12 col-lg-10"></div>
          </div>
        </section>

        {/* <ResourceInteruption cta_text={result.cta_text} cta_button_text={result.cta_button_text1}
                                     second_cta_button_text={result.second_cta_button_text} cta_link={result.cta_link}
                                     second_cta_button_link={result.second_cta_button_link} topMargin={false}/> */}
      </div>
    </AmazeePage>
  );
};

const StaticTypeAgencyPartnerBrochurePage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <AgencyPartnerBrochurePage location={location} data={data} />
      )}
    />
  );
};
export default StaticTypeAgencyPartnerBrochurePage;
